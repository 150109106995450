.App {
	.header {
		display: flex;
		justify-content: flex-end;

		// TODO: remove once I'm ready to add  the other pages.
		display: none;
	}

	.icon-row {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		width: 100%;
		margin-top: 8px;
		padding: 8px 0 0;
	}

	.footer {
		padding: 0;
	}

	.menu {
		background: #002766;
	}
}

