@import "breakpoints";

.About {
	display: flex;
	flex-direction: column;
	align-items: center;

	.content {
		background-color: #fff;
		width: 100%;
		height: 100%;
	}

	.title {
		margin-bottom: 0;
		color: white;
	}

	.subtitle {
		margin-top: 0;
		color: white;
	}

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #002766;
		width: 100%;
		padding: 16px 0;
	}

	.headline {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 16px;
		text-align: center;

		@media all and (min-width: $md) {
			max-width: 30%;
			min-width: 30%;
		}
	}

	.headline-section {
		display: flex;
		justify-content: space-around;
		width: 100%;

		@media all and (max-width: $md) {
			flex-direction: column;
			justify-content: center;
		}
	}

	.headline-header {
		text-align: center;
		margin-bottom: 20px;
	}

	.headline-icon {
		margin-bottom: 20px;
	}

	.round-icon {
		border-radius: 24px;
	}

	.skills-section {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		padding: 24px;
	}

	.skills-aside {
		padding-right: 84px;
		width: 100%;
	}

	.skills-header {
		text-align: center;
	}

	.skills-bar-section {
		margin-bottom: 24px;
	}
}
